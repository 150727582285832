import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
  ResponseType,
} from 'axios';
import _ from 'lodash';
import { ERoutePath } from '../interface-adaptaters/interfaces/router/route.interface';
import {
  deleteAccessToken, getAccessToken,
  getRefreshToken, setAccessToken,
  setRefreshToken,
} from './auth.service';

const BASE_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:8182';
// const BASE_URL_RD = 'https://rd-api.redesign.waltapp.tech'; // 'https://rd-api.waltapp.tech';
const BASE_URL_RD = process.env.REACT_APP_API_RD_URL ?? 'https://rd-api.waltapp.tech';
// const BASE_URL_RD = 'https://rd-api.waltapp.tech';
// const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://api.redesign.walterre.fr';
const BASE_STRIPE = 'https://api.stripe.com';

axios.defaults.timeout = 180000;

export type Primitive = string | number | Date | boolean | null | undefined;

export enum EMethod {
  // eslint-disable-next-line no-unused-vars
  POST = 'post',
  // eslint-disable-next-line no-unused-vars
  PUT = 'put',
  // eslint-disable-next-line no-unused-vars
  PATCH = 'patch',
  // eslint-disable-next-line no-unused-vars
  GET = 'get',
  // eslint-disable-next-line no-unused-vars
  DELETE = 'delete',
}

export interface IHeaders {
  'Content-Type'?: string;
  'x-api-key'?: string;
  'x-refresh-key'?: string;
  'Authorization'?: string;
  'grant_type'?: string;
}

export interface IData {
  [key: string]: Primitive;
}

export interface IAxios<T> {
  method: EMethod;
  url: string;
  headers?: IHeaders;
  data?: T;
  params?: string;
  query?: IQuery[];
  responseType?: ResponseType;
  signal?: AbortSignal;
}

export interface IQuery {
  [key: string]: string | undefined;
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const accessToken = getAccessToken();
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const onRequestRd = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const accessToken = process.env.REACT_APP_API_RD_TOKEN;
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const onRequestStripe = (config: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
  },
});
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<AxiosError| any> => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401 && _.get(error.response.data, 'message') !== 'Bad credentials') {
      deleteAccessToken();
      const refreshToken = getRefreshToken();

      try {
        const rs = await axios.post(`${BASE_URL}/auth/refresh`, {
          refreshToken,
        });

        const { accessToken, refreshToken: newRefreshToken } = rs.data;
        setAccessToken(accessToken);
        setRefreshToken(newRefreshToken);
        const originalRequest = error.config;
        if (originalRequest && originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return await axios(originalRequest); // Retry the original request with new token
        }
      } catch (_error) {
        window.location = ERoutePath.LOGIN as unknown as Location;
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw Promise.reject(_error);
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
export const setupInterceptorsToRd = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequestRd, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export const setupInterceptorsToStripe = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequestStripe, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export class UseAxios<T, Response> {
  private method: EMethod;

  private url: string;

  private urlWithOptions: string;

  private headers?: IHeaders;

  private data?: T;

  private params?: string;

  private query?: IQuery[];

  private responseType?: ResponseType;

  private signal?: AbortSignal;

  constructor(params: IAxios<T>) {
    this.method = params.method;
    this.url = params.url;
    this.headers = params.headers;
    this.data = params.data;
    this.params = params.params;
    this.query = params.query;
    this.urlWithOptions = params.url;
    this.responseType = params.responseType ?? 'json';
    this.signal = params.signal;
    this.createUrl();
  }

  public async exec(): Promise<Response> {
    const interceptors = setupInterceptorsTo(
      axios.create({
        baseURL: BASE_URL,
      }),
    );
    const request = await interceptors({
      method: this.method,
      headers: { ...this.headers },
      data: this.data,
      url: this.urlWithOptions,
    })
      .then((response) => response.data as Response)
      .catch((err) => err);
    // if (request[1] && (request[1] as Error).message === 'retry') {
    //   return interceptors({
    //     method: this.method,
    //     headers: { ...this.headers },
    //     data: this.data,
    //     url: this.urlWithOptions,
    //     responseType: this.responseType,
    //   })
    //     .then((response) => response.data as Response)
    //     .catch((err) => err);
    // }
    return request;
  }

  public async execRD(): Promise<Response> {
    const interceptors = setupInterceptorsToRd(
      axios.create({
        baseURL: BASE_URL_RD,
      }),
    );
    const request = await interceptors({
      method: this.method,
      headers: { ...this.headers },
      data: this.data,
      url: this.urlWithOptions,
      responseType: this.responseType,
      signal: this.signal,
    })
      .then((response) => response.data as Response)
      .catch((err) => err);
    if (request[1] && (request[1] as Error).message === 'retry') {
      return interceptors({
        method: this.method,
        headers: { ...this.headers },
        data: this.data,
        url: this.urlWithOptions,
        responseType: this.responseType,
        signal: this.signal,
      })
        .then((response) => response.data as Response)
        .catch((err) => err);
    }
    return request;
  }

  public async execStripe(): Promise<Response> {
    const interceptors = setupInterceptorsToStripe(
      axios.create({
        baseURL: BASE_STRIPE,
      }),
    );
    const request = await interceptors({
      method: this.method,
      headers: { ...this.headers },
      data: this.data,
      url: this.urlWithOptions,
      responseType: this.responseType,
      signal: this.signal,
    })
      .then((response) => response.data as Response)
      .catch((err) => err);
    if (request[1] && (request[1] as Error).message === 'retry') {
      return interceptors({
        method: this.method,
        headers: { ...this.headers },
        data: this.data,
        url: this.urlWithOptions,
        responseType: this.responseType,
        signal: this.signal,
      })
        .then((response) => response.data as Response)
        .catch((err) => err);
    }
    return request;
  }

  private createUrl(): void {
    if (this.params) {
      this.urlWithOptions += `/${this.params}`;
    }
    if (this.query && this.query.length > 0) {
      this.urlWithOptions += `?${this.query.map((value) => `${Object.keys(value)[0]}=${Object.values(value)[0]}`).join('&')}`;
    }
  }
}

export const customAxios = setupInterceptorsTo(
  axios.create({
    baseURL: BASE_URL,
  }),
);

export const useAxios = async <T, Response>({
  method, url, headers, data, params, query,
}: IAxios<T>): Promise<Response | unknown> => setupInterceptorsTo(
  axios.create({
    baseURL: BASE_URL,
  }),
)({
  method,
  url: `${url}/${params || ''}`,
  data,
  params: query,
  headers: { ...headers },
})
  .then((res) => res.data)
  .catch((err) => err);
